const RECOLLECTION_BASE_URL = '/recollection/'

const RECOLLECTION_SIGN_IN_URL = `${RECOLLECTION_BASE_URL}:partnerName/sign-in`
const RECOLLECTION_ENTER_CODE_URL = `${RECOLLECTION_BASE_URL}:partnerName/enter-code`

const RECOLLECTION_DOCS_REUPLOAD_URL = `${RECOLLECTION_BASE_URL}documents`
const RECOLLECTION_BASIC_INFO_URL = `${RECOLLECTION_DOCS_REUPLOAD_URL}/basic-info`
const RECOLLECTION_DRIVER_LICENSE_URL = `${RECOLLECTION_DOCS_REUPLOAD_URL}/driver-license`
const RECOLLECTION_INSURANCE_URL = `${RECOLLECTION_DOCS_REUPLOAD_URL}/insurance`
const RECOLLECTION_BGC_URL = `${RECOLLECTION_DOCS_REUPLOAD_URL}/bgc`

const RECOLLECTION_SUCCESS_URL = `${RECOLLECTION_BASE_URL}success`
const RECOLLECTION_NOT_FOUND_URL = `${RECOLLECTION_BASE_URL}not-found`
const RECOLLECTION_LOGGED_OUT_URL = `${RECOLLECTION_BASE_URL}logged-out`
const RECOLLECTION_PARTNER_DISABLED_URL = `${RECOLLECTION_BASE_URL}partner-disabled`

export const RECOLLECTION_ROUTES = {
  BASE_URL: RECOLLECTION_BASE_URL,

  SIGN_IN_URL: RECOLLECTION_SIGN_IN_URL,
  ENTER_CODE_URL: RECOLLECTION_ENTER_CODE_URL,

  DOCS_REUPLOAD_URL: RECOLLECTION_DOCS_REUPLOAD_URL,
  BASIC_INFO_URL: RECOLLECTION_BASIC_INFO_URL,
  DRIVER_LICENSE_URL: RECOLLECTION_DRIVER_LICENSE_URL,
  INSURANCE_URL: RECOLLECTION_INSURANCE_URL,
  BGC_URL: RECOLLECTION_BGC_URL,

  SUCCESS_URL: RECOLLECTION_SUCCESS_URL,
  NOT_FOUND_URL: RECOLLECTION_NOT_FOUND_URL,
  LOGGED_OUT_URL: RECOLLECTION_LOGGED_OUT_URL,
  PARTNER_DISABLED_URL: RECOLLECTION_PARTNER_DISABLED_URL,
}

const ONBOARDING_BASE_URL = '/onboarding/'

const ONBOARDING_SIGN_IN_URL = `${ONBOARDING_BASE_URL}:partnerName/sign-in`
const ONBOARDING_CONTINUE_SIGN_IN_URL = `${ONBOARDING_BASE_URL}:partnerName/continue-sign-in`
const ONBOARDING_SIGN_UP_URL = `${ONBOARDING_BASE_URL}:partnerName/sign-up`
const ONBOARDING_ENTER_CODE_URL = `${ONBOARDING_BASE_URL}:partnerName/enter-code`

const ONBOARDING_HOME_URL = `${ONBOARDING_BASE_URL}home`
const ONBOARDING_DOCS_URL = `${ONBOARDING_BASE_URL}docs`
const ONBOARDING_DRIVER_LICENSE_URL = `${ONBOARDING_BASE_URL}driver-license`
const ONBOARDING_VEHICLE_INSURANCE_URL = `${ONBOARDING_BASE_URL}vehicle-insurance`
const ONBOARDING_INSURANCE_DECLARATION_URL = `${ONBOARDING_BASE_URL}insurance-declaration`
const ONBOARDING_VEHICLE_REGISTRATION_URL = `${ONBOARDING_BASE_URL}vehicle-registration`
const ONBOARDING_VACCINATION_URL = `${ONBOARDING_BASE_URL}vaccination`
const ONBOARDING_BGC_URL = `${ONBOARDING_BASE_URL}bgc`
const ONBOARDING_GET_PAID_URL = `${ONBOARDING_BASE_URL}get-paid`
const ONBOARDING_INSURANCE_BENEFICIARY_URL = `${ONBOARDING_BASE_URL}insurance-beneficiary`
const ONBOARDING_SIGN_CONTRACT_URL = `${ONBOARDING_BASE_URL}sign-contract`
const ONBOARDING_TAKE_TRAINING_URL = `${ONBOARDING_BASE_URL}take-training`

const ONBOARDING_SUCCESS_URL = `${ONBOARDING_BASE_URL}success`
const ONBOARDING_NOT_FOUND_URL = `${ONBOARDING_BASE_URL}not-found`
const ONBOARDING_LOGGED_OUT_URL = `${ONBOARDING_BASE_URL}logged-out`
const ONBOARDING_PARTNER_DISABLED_URL = `${ONBOARDING_BASE_URL}partner-disabled`
const ONBOARDING_ALREADY_REGISTERED_URL = `${ONBOARDING_BASE_URL}already-registered`

export const ONBOARDING_ROUTES = {
  BASE_URL: ONBOARDING_BASE_URL,

  SIGN_IN_URL: ONBOARDING_SIGN_IN_URL,
  CONTINUE_SIGN_IN_URL: ONBOARDING_CONTINUE_SIGN_IN_URL,
  SIGN_UP_URL: ONBOARDING_SIGN_UP_URL,
  ENTER_CODE_URL: ONBOARDING_ENTER_CODE_URL,

  HOME_URL: ONBOARDING_HOME_URL,
  DOCS_URL: ONBOARDING_DOCS_URL,
  DRIVER_LICENSE_URL: ONBOARDING_DRIVER_LICENSE_URL,
  VEHICLE_INSURANCE_URL: ONBOARDING_VEHICLE_INSURANCE_URL,
  INSURANCE_DECLARATION_URL: ONBOARDING_INSURANCE_DECLARATION_URL,
  VEHICLE_REGISTRATION_URL: ONBOARDING_VEHICLE_REGISTRATION_URL,
  VACCINATION_URL: ONBOARDING_VACCINATION_URL,
  GET_PAID_URL: ONBOARDING_GET_PAID_URL,
  BGC_URL: ONBOARDING_BGC_URL,
  INSURANCE_BENEFICIARY_URL: ONBOARDING_INSURANCE_BENEFICIARY_URL,
  SIGN_CONTRACT_URL: ONBOARDING_SIGN_CONTRACT_URL,
  TAKE_TRAINING_URL: ONBOARDING_TAKE_TRAINING_URL,
  ALREADY_REGISTERED_URL: ONBOARDING_ALREADY_REGISTERED_URL,

  SUCCESS_URL: ONBOARDING_SUCCESS_URL,
  NOT_FOUND_URL: ONBOARDING_NOT_FOUND_URL,
  LOGGED_OUT_URL: ONBOARDING_LOGGED_OUT_URL,
  PARTNER_DISABLED_URL: ONBOARDING_PARTNER_DISABLED_URL,
}

const CONTRACTOR_PORTAL_BASE_URL = '/contractor/'

const CONTRACTOR_PORTAL_SIGN_IN_URL = `${CONTRACTOR_PORTAL_BASE_URL}sign-in`
const CONTRACTOR_PORTAL_SELECT_PARTNER_URL = `${CONTRACTOR_PORTAL_BASE_URL}select-partner`
const CONTRACTOR_PORTAL_ENTER_CODE_URL = `${CONTRACTOR_PORTAL_BASE_URL}enter-code`

const CONTRACTOR_PORTAL_HOME_URL = `${CONTRACTOR_PORTAL_BASE_URL}home`
const CONTRACTOR_PORTAL_INVOICES_URL = `${CONTRACTOR_PORTAL_BASE_URL}invoices`
const CONTRACTOR_PORTAL_DOCUMENTS_URL = `${CONTRACTOR_PORTAL_BASE_URL}documents`

const CONTRACTOR_PORTAL_SUCCESS_URL = `${CONTRACTOR_PORTAL_BASE_URL}success`
const CONTRACTOR_PORTAL_NOT_FOUND_URL = `${CONTRACTOR_PORTAL_BASE_URL}not-found`
const CONTRACTOR_PORTAL_LOGGED_OUT_URL = `${CONTRACTOR_PORTAL_BASE_URL}logged-out`
const CONTRACTOR_PORTAL_PARTNER_DISABLED_URL = `${CONTRACTOR_PORTAL_BASE_URL}partner-disabled`

export const CONTRACTOR_PORTAL_ROUTES = {
  BASE_URL: CONTRACTOR_PORTAL_BASE_URL,

  SIGN_IN_URL: CONTRACTOR_PORTAL_SIGN_IN_URL,
  SELECT_PARTNER_URL: CONTRACTOR_PORTAL_SELECT_PARTNER_URL,
  ENTER_CODE_URL: CONTRACTOR_PORTAL_ENTER_CODE_URL,

  HOME_URL: CONTRACTOR_PORTAL_HOME_URL,
  INVOICES_URL: CONTRACTOR_PORTAL_INVOICES_URL,
  DOCUMENT_URL: CONTRACTOR_PORTAL_DOCUMENTS_URL,

  SUCCESS_URL: CONTRACTOR_PORTAL_SUCCESS_URL,
  NOT_FOUND_URL: CONTRACTOR_PORTAL_NOT_FOUND_URL,
  LOGGED_OUT_URL: CONTRACTOR_PORTAL_LOGGED_OUT_URL,
  PARTNER_DISABLED_URL: CONTRACTOR_PORTAL_PARTNER_DISABLED_URL,
}
