import React, { memo } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { PublicWrapper } from 'src/components/layout/PublicWrapper'
import { colors } from 'src/theme'
import NotFoundImage from 'src/assets/images/not-found.png'
import { ONBOARDING_ROUTES } from 'src/constants/routes'
import { Button } from 'src/components/shared/Button'

interface Props {
  flow?: 'onboarding' | 'recollect' | 'contractorPortal'
}

const AlreadyRegisteredComponent = ({ flow }: Props) => {
  const [searchParams] = useSearchParams()
  const partnerName = searchParams.get('partnerName')
  const navigate = useNavigate()

  const handleGoToLogin = () => {
    const signInPath = generatePath(ONBOARDING_ROUTES.SIGN_IN_URL, { partnerName })
    navigate(signInPath)
  }

  return (
    <PublicWrapper flow={flow}>
      <div css={tw`h-full flex flex-col justify-between`}>
        <img src={NotFoundImage} css={tw`h-[220px] w-[280px] self-center`} />
        <div>
          <Typography css={tw`mt-4 text-center`} variant="h2">
            Contractor is already registered
          </Typography>
          <Typography css={tw`mb-4 mt-2 text-center`} color={colors.GRAY_DARK_COOL}>
            An account with your phone number already exists and is linked to {partnerName}. Please
            go to the login page and log in using your phone number.
          </Typography>
          <Button fullWidth color="black" onClick={handleGoToLogin}>
            Back to Login page
          </Button>
        </div>
      </div>
    </PublicWrapper>
  )
}

export const AlreadyRegisteredPage = memo(AlreadyRegisteredComponent)
